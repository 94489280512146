
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"

const qualifications = [
  "Manuelle Therapie",
  "Manuelle Reflextherapie",
  "Manuelle Lymphdrainage",
  "Faszientherapie",
  "CMD-Therapie (Kiefergelenk)",
  "Krankengymnastik ZNS PNF",
  "Fußreflexzonentherapie",
  "Kinesio Tape",
  "Marnitz-Therapie",
  "Cranio-Sacrale Therapie",
  "Viszerale Osteopathie bei INOMT",
  "Sektoraler Heilpraktiker für Physiotherapie",
]

const UberMich = () => (
  <Layout>
    <Seo title="Über Mich" />
    <section className="section">
      <div className="container is-max-desktop">
        <div className="content">
          <h1 className="title is-1 mb-6">Über Mich</h1>
          <div className="columns">
            <div className="column is-6">
              <h2 className="mb-6">Maxi Benndorf</h2>
              <div className="timeline">
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">2010</span>
                </header>
                <div className="timeline-item">
                  <div className="timeline-marker "></div>
                  <div className="timeline-content">
                    <p className="heading">Seit 2010</p>
                    <p>Staatlich anerkannte Physiotherapeutin</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-image is-16x16"></div>
                  <div className="timeline-content">
                    <p className="heading">2010-2021</p>
                    <p>Angestellte Physiotherapeutin in einer Praxis</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-green is-image is-32x32"></div>
                  <div className="timeline-content">
                    <p className="heading is-green">September 2021</p>
                    <p>Eröffnung der Praxis mitbewegt<br />in Schleußig, Leipzig</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <StaticImage
                src="../images/praxis/21-11-01_09-15-32_DSCF3004.jpg"
                alt="Maxi Benndorf lehnt am Eingang der Praxis Mitbewegt in Schleußig (Leipzig)"
                style={{ "borderRadius": "5px", "maxWidth": "400px" }}
                aspectRatio={4 / 5}
                transformOptions={{
                  cropFocus: "bottom"
                }}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <h2 className="my-5">Qualifikationen</h2>
              <div className="tags are-large">
                {qualifications.map(q => (
                  <span key={q} className="tag is-primary is-roundedx">{q}</span>
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default UberMich
